import { v4 as uuidv4 } from "uuid";
export const steps = [
    {
        step: 2,
        questionNumber: "Vraag 1 van 3",
        questionText: "Rook of vape jij??",
        options: [
            { text: "Ja, ik rook", id: "q1_yes" },
            { text: "Ja, ik vape", id: "q1_two" },
            { text: "Ja, beide", id: "q1_two_three" },
            { text: "Geen van beide", id: "q1_no" },
        ],
    },
    {
        step: 3,
        questionNumber: "Vraag 2 van 3",
        questionText: "Hoeveel sigaretten rook jij gemiddeld per dag?",
        options: [
            { text: "Minder dan 5 per dag", id: uuidv4() },
            { text: "Tussen de 5 en 10 sigaretten per dag", id: uuidv4() },
            { text: "Meer dan 10 per dag", id: uuidv4() },
        ],
    },
    {
        step: 4,
        questionNumber: "Vraag 3 van 3",
        questionText: "Wil jij graag succesvol stoppen met roken of vapen?",
        options: [
            { text: "Ja", id: "q4-yes" },
            { text: "Nee", id: "q4_no" },
            { text: "Misschien", id: "q4-yeses" },
        ],
    },
    {
        step: 5,
        questionNumber: "Vraag 2 van 3",
        questionText: "Hoeveel vape jij per week?",
        options: [
            { text: "Minder dan 100 puffs/trekjes per week", id: "q5-1" },
            { text: "100-250 puffs/trekjes per week", id: "q5-2" },
            { text: "251-500 puffs/trekjes per week", id: "q5-3" },
            { text: "Meer dan 500 puffs/trekkjes per week", id: "q5-4" },
        ],
    },
    {
        step: 6,
        // questionNumber: "Vraag 3 van 3",
        questionText: "We zijn blij dat jij niet rookt of vaped!",
        // options: [
        //     { text: "Ja", id: uuidv4() },
        //     { text: "Nee", id: "q4_no" },
        //     { text: "Misschien", id: uuidv4() },
        // ],
        utag: "Maar ik ontvang graag stopadvies voor iemand anders.",
    },
];
