import uiTwoImage from "../assets/img/blackWoman.png";
import uiThreeImage from "../assets/img/girlUIThree.png";
import uiFourImg from "../assets/img/fourUIImg.png";
import uiFiveImg from "../assets/img/uiFiveImg.png";
import uiSixImg from "../assets/img/uiSixImage.png";
import uiSevenImg from "../assets/img/uiSevenImage.png";
import uiEightImg from "../assets/img/uiEightImage.png";
import uiNineImg from "../assets/img/uiNineImage.png";
import uiElevenImg from "../assets/img/uiElevenImage.png";
import uiFourteenImg from "../assets/img/uiFourteenImage.png";

import { v4 as uuidv4 } from "uuid";

export const stepData = [
    {
        step: 1,
        title: "Hoeveel sigaretten rook jij gemiddeld per dag?",
        questions: [
            { id: uuidv4(), text: "Minder dan 5 per dag" },
            { id: uuidv4(), text: "Tussen de 5 en 10 sigaretten per dag" },
            { id: uuidv4(), text: "Meer dan 10 per dag" },
        ],
        imgSrc: uiTwoImage,
    },
    {
        step: 2,
        title: "Waarom rook je?",
        questions: [
            { id: uuidv4(), text: "Emoties" },
            { id: uuidv4(), text: "Gewoonte" },
            { id: uuidv4(), text: "Gezelligheid" },
            { id: uuidv4(), text: "Verslaving" },
            { id: uuidv4(), text: "Het is rustgevend" },
        ],
        imgSrc: uiThreeImage,
    },
    {
        step: 3,
        title: "Heb je wel eens geprobeerd te stoppen met roken?",
        questions: [
            { id: "q1_yes", text: "Ja" },
            { id: "q1_no", text: "Nee" },
        ],
        imgSrc: uiFourImg,
    },
    {
        step: 4,
        title: "Waarom wilde je stoppen met roken?",
        questions: [
            { id: uuidv4(), text: "Gezondheid" },
            { id: uuidv4(), text: "Geld besparen" },
            { id: uuidv4(), text: "Voor mijn kinderen" },
            { id: uuidv4(), text: "Vruchtbaarheid" },
            { id: uuidv4(), text: "Uiterlijk" },
            { id: uuidv4(), text: "Anders" },
        ],
        imgSrc: uiFiveImg,
    },
    {
        step: 5,
        title: "Op welke manier(en) heb je destijds geprobeerd te stoppen?",
        questions: [
            { id: uuidv4(), text: "Zelf afbouwen" },
            { id: uuidv4(), text: "Met coaching" },
            { id: uuidv4(), text: "Cold turkey" },
            {
                id: uuidv4(),

                text: "Hulpmiddel (bijv. nicotine pleisters)",
            },

            { id: uuidv4(), text: "Anders" },
        ],
        imgSrc: uiSixImg,
    },
    {
        step: 6,
        title: "Zou je ooit weer willen proberen te stoppen met roken?",
        questions: [
            { id: "q2_yes", text: "Ja" },
            { id: "q2_no", text: "Nee" },
        ],
        imgSrc: uiSevenImg,
    },
    {
        step: 7,
        title: "Iedereen weet dat roken slecht is voor de gezondheid. Wij zijn benieuwd hoeveel zorgen jij je maakt over de volgende situaties.",
        questions: [],
        imgSrc: uiEightImg,
    },
    {
        step: 8,
        title: "Veel rokers zien hun (klein)kinderen niet opgroeien.",
        questions: [
            { id: uuidv4(), text: "Dat vind ik schokkend" },
            { id: uuidv4(), text: "Neutraal" },
            { id: uuidv4(), text: "Dat maakt me niks uit" },
        ],
        imgSrc: uiNineImg,
    },
    {
        step: 9,
        title: "Gemiddeld overlijdt een man die een pakje per dag rookt al voor zijn pensioen",
        questions: [
            { id: uuidv4(), id: 26, text: "Dat vind ik schokkend" },
            { id: uuidv4(), id: 27, text: "Neutraal" },
            { id: uuidv4(), id: 28, text: "Dat maakt me niet uit" },
        ],
        imgSrc: uiTwoImage,
    },

    {
        step: 10,
        title: `
        Kennisvraag: Hoeveel keer succesvoller is stoppen met hulp tov "cold turkey"?`,
        questions: [
            {
                id: uuidv4(),
                text: "2 keer succesvoller",
                bgClass: "setbackground-color__red text-white",
            },
            {
                id: uuidv4(),
                text: "5 keer succesvoller",
                bgClass: "setbackground-color__red",
            },
            {
                id: uuidv4(),
                text: "10 keer succesvoller",
                bgClass: "setbackground-color__green",
            },
        ],
        imgSrc: uiElevenImg,
    },
    {
        step: 11,
        title: `
        Hebben deze vragen je laten her-overwegen toch ooit te stoppen met roken?`,
        questions: [
            { id: "q3_yes", text: "Ik wil niet stoppen met roken" },
            { id: "q3_no", text: "Ik wil wel stoppen met roken" },
        ],
        imgSrc: uiSevenImg,
    },
    {
        step: 12,
        title: `
        Wil je ooit stoppen met roken?`,
        questions: [
            { id: "q4_yes", text: "Ja" },
            { id: "q4_no", text: "Nee" },
        ],
        imgSrc: uiSevenImg,
    },
    {
        step: 13,
        title: "Waarom zou je willen stoppen met roken?",
        questions: [
            { id: uuidv4(), text: "Gezondheid" },
            { id: uuidv4(), text: "Geld besparen" },
            { id: uuidv4(), text: "Voor mijn kinderen" },
            { id: uuidv4(), text: "Vruchtbaarheid" },
            { id: uuidv4(), text: "Uiterlijk" },
            { id: uuidv4(), text: "Anders" },
        ],
        imgSrc: uiFourteenImg,
    },
];
