import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PrivacyText from "./PrivacyText";

const Footer = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const toggle = () => {
        setIsOpenModal(!isOpenModal);
    };
    return (
        <>
            <div className="">
                <div className="d-sm-flex justify-content-between align-items-center footer">
                    <h6>Stop Bewust</h6>
                    <span className="pointer" onClick={() => toggle()}>
                        Privacy statement
                    </span>
                </div>
            </div>
            <PrivacyText isOpen={isOpenModal} toggle={toggle} />
        </>
    );
};
export default Footer;
