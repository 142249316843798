import React, { useEffect, useState } from "react";
import HeroSection from "../components/Stopadvies/HeroSection";
import QuestionSection from "../components/Stopadvies/QuestionSection";
import FormSection from "../components/Stopadvies/FormSection";
import ThankYouPage from "../components/Stopadvies/ThankYouPage";
import { steps } from "../components/Stopadvies/data";
import Header from "../components/Stopadvies/Header";
import Footer from "../components/Stopadvies/Footer";
import ErrorPage from "../components/Stopadvies/errorPage";
const Stopadvies = () => {
    useEffect(() => {
        document.title = "Stop Bewust";
    }, []);

    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };
    let currentComponent;
    let currentBgClass;
    switch (step) {
        case 1:
            currentComponent = <HeroSection nextStep={nextStep} />;
            currentBgClass = "HeroImg";
            break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
            const currentStep = steps[step - 2];
            currentComponent = (
                <QuestionSection
                    step={step}
                    setStep={setStep}
                    question={currentStep}
                />
            );
            currentBgClass =
                step === 2
                    ? "bgImg2"
                    : step === 3
                    ? "bgImg3"
                    : step === 4
                    ? "bgImg4"
                    : step === 5
                    ? "bgImg5"
                    : "bgImg6";
            // step === 6 ? "bgImg6";
            break;
        case 7:
            currentComponent = <FormSection setStep={setStep} />;
            currentBgClass = "FormImage";
            break;
        case 8:
            currentComponent = (
                <ThankYouPage>
                    <h3>
                        Bedankt voor het aanvragen van jouw gratis stopadvies.
                    </h3>
                    <h4>
                        Stichting Stop Bewust neemt zo snel mogelijk contact met
                        je op voor het advies!
                    </h4>
                </ThankYouPage>
            );
            currentBgClass = "FormImage";
            break;
        case 9:
            currentComponent = <ErrorPage setStep={setStep} />;
            currentBgClass = "ThankYouImage";
            break;
        case 10:
            currentComponent = (
                <ThankYouPage>
                    <h3>Bedankt voor het mee doen!</h3>
                </ThankYouPage>
            );
            currentBgClass = "FormImage";
            break;
        default:
            currentComponent = null;
            currentBgClass = null;
    }

    return (
        <div className="stop-advise">
            <div className={`StopadviesContainer ${currentBgClass}`}>
                <Header setStep={setStep} />
                {currentComponent}
            </div>
            <Footer />
        </div>
    );
};

export default Stopadvies;
