import React, { useState } from "react";
import { Button, Card, Col, Input } from "reactstrap";
import InputCards from "./inputCards";
import { Link } from "react-router-dom/cjs/react-router-dom";

const QuestionSection = ({ step, setStep, question }) => {
    const [questionOption, setQuestionOption] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    let [selectedOptions, setSelectedOptions] = useState([]);
    console.log(selectedOptions);
    const handleOptionChange = (option) => {
        setSelectedOption(option);
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[step - 2] = option;
        setSelectedOptions(updatedSelectedOptions);
    };
    const handleNext = () => {
        const options = ["q5-1", "q5-2", "q5-3", "q5-4"];
        console.log(selectedOption);
        if (step == 6) {
            setStep(1);
        } else if (options.includes(selectedOption)) {
            setStep(4);
        } else if (step == 3 && questionOption) {
            setStep(5);
        } else if (selectedOption == "q1_two") {
            setStep(5);
        } else if (selectedOption == "q4-yes" || selectedOption == "q4-yeses") {
            setStep(7);
        } else if (selectedOption == "q1_two_three") {
            setStep(3);
            setQuestionOption(true);
        } else if (selectedOption == "q1_no") {
            setStep(6);
        } else if (selectedOption == "q4_no") {
            setStep(10);
        } else {
            setStep((prev) => prev + 1);
        }
        setSelectedOption(null);
    };

    return (
        <div className="Herocontainer row">
            <Card className="col-md-12 col-lg-6 HeroCard questionSection">
                {step == 6 ? (
                    ""
                ) : (
                    <div className="line-container">
                        {Array.from({ length: 3 }).map((_, currentStep) => (
                            <div
                                key={currentStep}
                                className={`line ${
                                    step == 5
                                        ? 2 >= currentStep + 2
                                            ? "bgBlue"
                                            : "bgWhite"
                                        : step >= currentStep + 2
                                        ? "bgBlue"
                                        : "bgWhite"
                                }`}
                            >
                                {console.log(
                                    step == 5
                                        ? 2
                                        : step >= currentStep + 2
                                        ? "bgBlue"
                                        : "bgWhite"
                                )}
                            </div>
                        ))}
                    </div>
                )}

                <h6>
                    {question?.questionNumber ? question?.questionNumber : ""}
                </h6>
                <h2 className="mb-5">{question.questionText}</h2>
                <div
                // className={` ${step === 2 ? "radioInput" : ""}`}
                >
                    {question?.options ? (
                        question.options.map((input, index) => (
                            <div
                                key={index}
                                class="form-check mb-4 stop-advise-check"
                            >
                                <Input
                                    className="form-check-input pointer"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id={input.id}
                                    checked={selectedOptions.includes(input.id)}
                                    onChange={() =>
                                        handleOptionChange(input.id)
                                    }
                                />
                                <label
                                    className="form-check-label pointer mx-3"
                                    for={input.id}
                                >
                                    {input.text}
                                </label>
                            </div>
                        ))
                    ) : (
                        <u
                            className="question-u pointer"
                            onClick={() => setStep(7)}
                        >
                            {question.utag}
                        </u>
                    )}
                </div>
                <Button
                    color="site-success"
                    onClick={handleNext}
                    disabled={
                        !selectedOption
                        // (step === 2 && selectedOption < 1) ||
                        // (step === 3 && selectedOption < 2) ||
                        // (step === 4 && selectedOption < 3) ||
                        // (step === 5 && selectedOption < 4)
                    }
                >
                    {step === 6 ? "Afsluiten" : "Volgende"}
                </Button>
                {/* {step > 2 && step < 6 && (
                    <p
                        className="text-secondary pointer mx-2 back-question-text"
                        onClick={() => setStep((prev) => prev - 1)}
                    >
                        Vorige vraag
                    </p>
                )} */}
            </Card>
        </div>
    );
};

export default QuestionSection;
