import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const PrivacyText = ({ isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
            <ModalHeader toggle={toggle}>Privacy Policy</ModalHeader>
            <ModalBody>
                <p>
                    Nationaal Rokersonderzoek is een initiatief van Stichting
                    Stop Bewust.
                </p>

                <h1>PRIVACYVERKLARING STICHTING STOP BEWUST</h1>
                <p>
                    Stichting Stop Bewust (hierna: SSB) biedt informatie en
                    advies over stoppen met roken. Stichting Stop Bewust
                    respecteert de privacy van eenieder van wie persoonsgegevens
                    door SSB worden verwerkt, waaronder mede begrepen die van
                    bezoekers van de website www.stichtingstopbewust.nl (hierna:
                    “deze website”). SSB acht de zorgvuldige omgang met
                    persoonsgegevens van groot belang. SSB zal uw
                    persoonsgegevens verwerken in overeenstemming met de
                    Algemene Verordening Gegevensbescherming (Verordening (EU)
                    2016/679 van
                    <br />
                    het Europees Parlement en de Raad van 27 april 2016).
                    <br />
                    SSB verwerkt persoonsgegevens op basis van de volgende vier
                    wettelijke grondslagen:
                </p>
                <p>
                    <strong>1) Uw toestemming</strong>
                </p>

                <p>Van deze grondslag is sprake wanneer:</p>

                <ol>
                    <li>
                        u via een van de contactformulieren of de chatmodule op
                        deze website, per e-mail, post, via social media of op
                        welke andere wijze dan ook contact opneemt met SSB, zich
                        via deze website inschrijft voor de ontvangst van
                        nieuwbrieven of wanneer u op deze website een review
                        schrijft. Uw toestemming ligt immers impliciet besloten
                        in het feit dat u contact opneemt, zich inschrijft voor
                        de ontvangst van nieuwsbrieven of een review schrijft;
                    </li>
                    <li>
                        u naar aanleiding van het contact met SSB over stoppen
                        met roken toestemming aan SSB geeft om uw
                        persoonsgegevens te delen met een hulpverlener of om u
                        door te verbinden met een hulpverlener;
                    </li>
                    <li>
                        SSB uw persoonsgegevens voor nog andere doeleinden dan
                        genoemd onder a) en hieronder onder 2), 3) en 4) wil
                        gebruiken. In dit kader geldt dat uw toestemming steeds
                        geldt voor een specifieke verwerking en een specifiek
                        doel. Indien SSB meerdere doeleinden heeft om
                        persoonsgegevens te verwerken, dan zal SSB u hierover
                        informeren en voor elk doel afzonderlijk toestemming
                        vragen.
                    </li>
                </ol>

                <p>
                    In het kader van de wettelijke grondslag “toestemming”
                    verwerkt SSB uw persoonsgegevens uitsluitend indien en voor
                    zover dat nodig is om uw verzoek in behandeling te nemen of
                    uitvoering te geven aan hetgeen waarvoor u toestemming hebt
                    gegeven. Indien u zich voor de ontvangst van nieuwbrieven
                    hebt ingeschreven, kunt u zich voor de verdere ontvangst
                    daarvan uitschrijven op de daartoe in de nieuwsbrieven
                    aangegeven wijze.
                </p>

                <p>
                    <strong>
                        2) Ter uitvoering van een overeenkomst tussen u en SSB
                        of uw verzoek tot het sluiten van een dergelijke
                        overeenkomst.
                    </strong>
                </p>

                <p>
                    Van deze grondslag is sprake wanneer u als bedrijf
                    (consultancy)diensten van SSB afneemt of daarvoor
                    bijvoorbeeld een offerte heeft aangevraagd. Ook is hiervan
                    sprake indien tussen u en SSB een overeenkomst bestaat in
                    het kader waarvan u SSB sponsort of een donatie aan SSB
                    verricht.
                    <br />
                    In het kader van deze wettelijke grondslag voor het
                    verwerken van uw persoonsgegevens, verwerkt SSB uw
                    persoonsgegevens uitsluitend indien en voor zover dat
                    noodzakelijk is ter uitvoering van de met u gesloten
                    overeenkomst. In dit verband kunnen uw persoonsgegevens ook
                    met derden worden gedeeld voor zover dat noodzakelijk is
                    voor de uitvoering van de betreffende overeenkomst, zoals
                    met externe dienstverleners met wie SSB samenwerkt bij de
                    uitvoering van overeenkomsten.
                </p>

                <p>
                    <strong>3) Een gerechtvaardigd belang.</strong>
                </p>

                <p>Van deze grondslag is sprake bij:</p>

                <ol>
                    <li>
                        het verstrekken van uw persoonsgegevens aan de door SSB
                        ingeschakelde hostingpartij die het functioneren van
                        deze website mogelijk maakt. Dit is nodig om de middels
                        deze website verstrekte persoonsgegevens op te slaan en
                        om deze website en emailvoorzieningen van SSB deugdelijk
                        te kunnen laten werken;
                    </li>
                    <li>
                        het door SSB verstrekken van persoonsgegevens aan een
                        boekhoudkantoor om de administratie en boekhouding van
                        SSB te kunnen verzorgen;
                    </li>
                    <li>
                        het door SSB automatisch verstrekken van
                        persoonsgegevens aan de exploitant van de door SSB
                        gebruikte callcentersoftware, software voor het
                        versturen van nieuwsbrieven en software voor het
                        beantwoorden van reacties via social media. Dit is voor
                        SSB nodig om van de functionaliteiten van deze software
                        gebruik te kunnen maken en om haar dienstverlening te
                        kunnen verbeteren;
                    </li>
                    <li>
                        het verwerken door SSB van persoonsgegevens die niet van
                        de betrokkene zelf afkomstig zijn. Dit is aan de orde
                        wanneer met SSB samenwerkende partijen – met toestemming
                        van de betrokkene – persoonsgegevens aan SSB
                        verstrekken. Deze verwerking van persoonsgegevens
                        gebeurt uitsluitend indien en voor zover dat
                        noodzakelijk is voor de doeleinden waarvoor de
                        betrokkene toestemming heeft gegeven aan de betreffende
                        met SSB samenwerkende partij.
                    </li>
                </ol>
                <p>
                    In het kader van ‘gerechtvaardigde belangen’ verwerkt SSB uw
                    persoonsgegevens uitsluitend indien en voor zover dat
                    noodzakelijk is om de betreffende gerechtvaardigde belangen
                    te dienen.
                </p>
                <p>
                    <strong>4) Een wettelijke verplichting.</strong>
                </p>

                <p>
                    Van deze grondslag is sprake wanneer SSB moet voldoen aan
                    een wettelijke verplichting, zoals wat betreft het bewaren
                    van facturen en loonadministraties gedurende zeven jaren
                    zoals door de wet voorgeschreven.
                </p>

                <p>
                    <span>Toegang tot persoonsgegevens</span>
                    <br />
                    Uitsluitend daartoe geautoriseerde medewerkers van SSB
                    hebben toegang tot uw persoonsgegevens en uitsluitend voor
                    zover dat nodig is ter uitvoering van de aan hen opgedragen
                    taken. Dit geldt ook ten aanzien van derde-partijen zoals
                    hierboven bedoeld en met wie SSB persoonsgegevens deelt. SSB
                    zal uw persoonsgegevens onder geen beding verkopen. De
                    personen die onder verantwoordelijkheid van SSB vallen,
                    evenals door SSB ingeschakelde derden, zijn verplicht om de
                    vertrouwelijkheid van uw persoonsgegevens te respecteren.
                    Voor zover SSB de verwerking van persoonsgegevens
                    uitbesteedt aan een derde partij, zal SSB de noodzakelijke
                    stappen ondernemen om te garanderen dat uw persoonsgegevens
                    voldoende worden beschermd. Zulke stappen omvatten, indien
                    en voor zover dat volgens de wet is vereist, het hanteren
                    van een verwerkersovereenkomst met dergelijke ontvangers om
                    te zorgen dat zij dezelfde of vergelijkbare technische en
                    organisatorische maatregelen treffen als SSB, zodat uw
                    persoonsgegevens voldoende worden beschermd.
                </p>

                <p>
                    <span>Uw rechten</span>
                    <br />
                    U heeft het recht om SSB te verzoeken om inzage, wissing en
                    rectificatie van uw persoonsgegevens alsook om beperking van
                    de u betreffende verwerking. Ook heeft u het recht om tegen
                    de verwerking bezwaar te maken en heeft u recht op
                    gegevensoverdraagbaarheid. Dat betekent dat u bij SSB een
                    verzoek kunt indienen om uw persoonsgegevens die SSB
                    verwerkt, in een computerbestand naar u of een door u
                    genoemde organisatie te sturen. Om er zeker van te zijn dat
                    het verzoek door u is gedaan, vraagt SSB u een kopie van uw
                    identiteitsbewijs met het verzoek mee te sturen. Maak in
                    deze kopie de MRZ (machine readable zone, de strook met
                    nummers op het document), documentnummer en
                    <br />
                    Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw
                    privacy. SSB zal zo snel mogelijk, maar in elk geval binnen
                    vier weken,
                    <br />
                    reageren op uw verzoek. Voor zover de verwerking van
                    persoonsgegevens gebaseerd is op de van u verkregen
                    toestemming, heeft u te allen tijde het recht deze
                    toestemming in te trekken, zonder dat dit afbreuk doet aan
                    de rechtmatigheid van de verwerking op basis van de
                    toestemming vóór de intrekking daarvan. U heeft het recht om
                    een klacht in te dienen bij de Autoriteit Persoonsgegevens
                    of in geval u in een ander land dan Nederland woonachtig
                    bent, de toezichthoudende autoriteit aldaar.
                </p>
                <p>
                    <span>Bewaren van persoonsgegevens</span>
                    Persoonsgegevens worden door SSB slechts bewaard voor zolang
                    dat gelet op de doeleinden waarvoor zij zijn verkregen,
                    noodzakelijk is. SSB legt passende technische en
                    organisatorische maatregelen ten uitvoer om persoonsgegevens
                    te beveiligen tegen verlies en tegen enige vorm van
                    onrechtmatige verwerking. Deze maatregelen garanderen,
                    rekening houdend met de stand van de techniek en de kosten
                    van de tenuitvoerlegging, een passend beveiligingsniveau
                    gelet op de risico’s die de verwerking en de aard van te
                    beschermen persoonsgegevens met zich meebrengen. De
                    maatregelen zijn er mede op gericht onnodige verwerking van
                    persoonsgegevens te voorkomen.
                </p>

                <p>
                    <span>Contact</span>
                    <br />
                    Voor vragen over deze privacyverklaring of vragen of
                    klachten over de verwerking van uw persoonsgegevens door
                    SSB, kunt u contact opnemen met de Functionaris voor de
                    Gegevensbescherming van SSB. Deze persoon helpt u graag
                    indien u vragen of klachten heeft over de verwerking van uw
                    persoonsgegevens door SSB of indien u uw persoonsgegevens
                    wilt wijzigen of verwijderen.
                </p>

                <p>
                    <strong>Stichting Stop Bewust</strong>
                    <br />
                    Keizersgracht 391A
                    <br />
                    1016 EJ Amsterdam
                </p>
                <p>
                    <span style={{ textDecoration: "underline" }}>
                        Functionaris voor de Gegevensbescherming:
                    </span>
                    <br />
                    Michiel van Mameren
                    <br />
                    Telefoonnummer: 088-5006600
                </p>
            </ModalBody>
        </Modal>
    );
};

export default PrivacyText;
