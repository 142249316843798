import React from "react";
import { Card } from "reactstrap";
const ThankYouPage = ({ setStep }) => {
    const handleClick = () => {
        setStep(1);
    };
    return (
        <div className="errorcontainer row">
            <Card className=" col-sm-10 col-md-8 col-lg-5 errorCard">
                <h5>Dat is goed nieuws! Wij zijn blij dat jij niet rookt!</h5>
                <button className="errorBtn" onClick={handleClick}>
                    Afsluiten
                </button>
            </Card>
        </div>
    );
};

export default ThankYouPage;
