import React from "react";
import {
    Card,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Spinner,
} from "reactstrap";
import arrow from "../../assets/img/stopdivise/longarrowleft.png";
import GreenTick from "../../assets/img/stopdivise/checkMark.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createLead, setErrorResponse } from "../../store/actions/Action";
const FormSection = ({ setStep }) => {
    let data = [
        {
            data: "Ervaring wijst uit dat mensen twee keer zoveel motivatie hebben na een adviesgesprek van Stichting Stop Bewust.",
            class: "",
        },
        {
            data: "Geen winstoogmerk: altijd een gratis, eerlijk advies.",
            class: "",
        },
        {
            data: "Advies over vergoedingen vanuit de zorgverzekeraar.",
            class: "",
        },
        {
            data: "Geen winstoogmerk: altijd een gratis, eerlijk advies.",
            class: "d-none d-md-block",
        },
        {
            data: "Je kan al je rookvragen bespreken.",
            class: "d-none d-md-block",
        },
    ];
    const dispatch = useDispatch();
    const history = useHistory();

    console.log(history);
    const { isLoading, error_response } = useSelector((state) => state.leads);

    const query = history.location.search;
    const site_subid = new URLSearchParams(query).get("oa_id");
    const publisher_id = new URLSearchParams(query).get("site");
    const [success, setSuccess] = useState(false);
    const [bootCheck, setBootCheck] = useState(false);
    const [formData, setFormData] = useState({
        language: "nl_NL",
        site_subid: site_subid,
        publisher_id: publisher_id,
        site_custom_url: "stopbewust.gratismeedoen.nl",
        site_custom_name: "nationaal_rokers_onderzoek",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        answers: [3836],
    });
    const checkNameRegex = (val) => {
        const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
        if (val !== "") {
            if (nameRegex.test(val)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const checkEmailRegex = (val) => {
        const emailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/;
        if (val !== "") {
            if (emailRegex.test(val)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const checkPhoneRegex = (val) => {
        const phoneRegex = /^(0|\+?31|00\s?31)([-\s]?\d[-\s]?){7,8}\d$/;
        if (val !== "") {
            if (phoneRegex.test(val)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (error_response?.[name]) {
            dispatch(setErrorResponse());
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            checkNameRegex(formData?.firstname) ||
            checkNameRegex(formData?.lastname) ||
            checkEmailRegex(formData?.email) ||
            checkPhoneRegex(formData?.phone_number)
        ) {
            // alert("Please fill the fields properly to submit");
            return false;
        } else {
            formData.bootCheck = bootCheck;
            const phoneNumberWithoutPunctuation = formData.phone_number
                .replace(/[\s-]/g, "")
                .replace(/^00(\d+)$/, "$1");
            let newArray = {
                ...formData,
                phone_number: phoneNumberWithoutPunctuation,
            };
            console.log("newArray: ", newArray);

            dispatch(
                createLead(
                    newArray,
                    () => {
                        setFormData({
                            language: "",
                            publisher_id: "", // site_id
                            site_subId: "", // oa_id
                            site_custom_url: "",
                            site_custom_name: "",
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone_number: "",
                            answers: [],
                        });
                        setSuccess(true);

                        setTimeout(() => {
                            setStep(8);
                        }, 2000);
                    },
                    () => {
                        setSuccess(false);
                    }
                )
            );
        }
    };
    return (
        <Container>
            <div
                className="formContainer row d-flex
    justify-content-center"
            >
                <Col lg="6" className="p-0 pr-1">
                    <Card className=" formIntroCard">
                        <div className="absoluteText bag-text-form d-none d-md-block">
                            <p className="text-white absoluteP">
                                Al meer dan 200.000
                                <br />
                                mensen gingen je voor
                            </p>
                        </div>{" "}
                        <h4 className="mb-3">
                            Bedankt! Jij komt in aanmerking voor een GRATIS
                            Stopadvies
                        </h4>
                        <h3 className="mb-4 mt-1">
                            {/* Ontvang gratis en vrijblijvend jouw stopadvies: */}
                            Wij helpen je gratis en vrijblijvend met persoonlijk
                            advies over de juiste aanpak.
                        </h3>
                        {data.map((e) => {
                            return (
                                <div className={`my-1 cards ${e.class}`}>
                                    <h5 className="d-flex mb-0">
                                        <img
                                            src={GreenTick}
                                            className="mr-4 GreenTick"
                                        />
                                        <span className="poppins">
                                            {e.data}
                                        </span>
                                    </h5>
                                </div>
                            );
                        })}
                        <div className="lastPart">
                            <p>
                                Vul hier jouw gegevens in en ontvang jouw GRATIS
                                Stopadvies + alle informatie en advies om
                                succesvol te stoppen met roken of vapen!
                            </p>
                            <img
                                src={arrow}
                                className="arrowSign d-none d-lg-block"
                            />
                        </div>
                    </Card>
                </Col>
                <Col lg="5" className="p-0">
                    <Card className="formSection">
                        <h4>
                            Ontvang gratis en vrijblijvend jouw stopadvies:
                            {/* Vul hier jouw gegevens in en ontvang jouw GRATIS
                            Stopadvies + alle informatie en advies om succesvol
                            te stoppen met roken! */}
                        </h4>
                        <div
                            className="position-relative d-flex     justify-content-end
    align-items-center"
                        >
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.0"
                                width="87.000000pt"
                                height="165.000000pt"
                                viewBox="0 0 87.000000 165.000000"
                                preserveAspectRatio="xMidYMid meet"
                                className="arrow"
                            >
                                <g
                                    transform="translate(0.000000,165.000000) scale(0.100000,-0.100000)"
                                    fill="#D77B2B"
                                    stroke="none"
                                >
                                    <path d="M272 1628 c-11 -14 -15 -33 -13 -59 0 -9 32 -39 69 -67 291 -216 468 -613 397 -887 -47 -179 -132 -278 -322 -375 -84 -43 -96 -57 -67 -81 23 -19 152 16 226 61 325 198 388 620 151 1021 -53 89 -90 136 -168 215 -97 97 -213 184 -246 184 -9 0 -21 -6 -27 -12z" />
                                    <path d="M122 352 c-29 -40 -77 -158 -98 -240 -15 -58 -16 -74 -6 -84 18 -18 317 -24 396 -8 71 14 106 29 106 46 0 21 -12 23 -95 12 -48 -7 -130 -8 -206 -3 l-127 7 40 96 c50 124 58 176 30 191 -18 9 -24 7 -40 -17z" />
                                </g>
                            </svg> */}
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup className="my-4">
                                <Label className="fw-bold" for="firstName">
                                    Voornaam
                                </Label>
                                <Input
                                    id="firstName"
                                    className="Input-border"
                                    placeholder="Pieter"
                                    type="text"
                                    name="firstname"
                                    required
                                    value={formData?.firstname}
                                    onChange={handleChange}
                                    invalid={
                                        checkNameRegex(formData?.firstname) ||
                                        error_response?.firstname
                                    }
                                />
                                <FormFeedback>
                                    Voornaam niet correct
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className="my-4">
                                <Label className="fw-bold" for="lastName">
                                    Achternaam
                                </Label>
                                <Input
                                    id="lastName"
                                    className="Input-border"
                                    placeholder="Peters"
                                    type="text"
                                    name="lastname"
                                    value={formData?.lastname}
                                    required
                                    onChange={handleChange}
                                    invalid={
                                        checkNameRegex(formData?.lastname) ||
                                        error_response?.lastname
                                    }
                                />
                                <FormFeedback>
                                    Achternaam niet correct
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className="my-4">
                                <Label className="fw-bold" for="Email">
                                    E-mailadres
                                </Label>
                                <Input
                                    id="Email"
                                    className="Input-border"
                                    placeholder="naam@email.nl"
                                    type="email"
                                    name="email"
                                    required
                                    value={formData?.email}
                                    onChange={handleChange}
                                    invalid={
                                        checkEmailRegex(formData?.email) ||
                                        error_response?.email
                                    }
                                />
                                <FormFeedback>
                                    E-mailadres niet correct
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup className="my-4">
                                <Label className="fw-bold" for="phone_number">
                                    Telefoonnummer{" "}
                                </Label>
                                <Input
                                    id="phone_number"
                                    className="Input-border"
                                    type="text"
                                    placeholder="+31"
                                    name="phone_number"
                                    required
                                    invalid={
                                        checkPhoneRegex(
                                            formData?.phone_number
                                        ) || error_response?.phone_number
                                    }
                                    onChange={handleChange}
                                />
                                <FormFeedback>
                                    Telefoonnummer niet correct
                                </FormFeedback>
                            </FormGroup>

                            <div className=" my-4 mx-1 FormBtn">
                                <Input
                                    type="checkbox"
                                    value={bootCheck}
                                    onChange={() => setBootCheck(!bootCheck)}
                                    style={{ opacity: "0.001" }}
                                />
                                <button
                                    className=""
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    {isLoading ? (
                                        <span className="d-flex justify-content-center align-items-center mx-auto">
                                            <Spinner size="sm" />
                                        </span>
                                    ) : (
                                        <>
                                            Bevestig
                                            <i class="fas fa-chevron-right mx-3"></i>
                                        </>
                                    )}
                                </button>
                            </div>
                            <p className="px-2 pt-2">
                                Door op "bevestig" te klikken, ga je er mee
                                akkoord dat er éénmalig telefonisch contact met
                                je opgenomen wordt voor een persoonlijk
                                adviesgesprek.
                            </p>
                        </Form>
                    </Card>{" "}
                </Col>
            </div>
        </Container>
    );
};

export default FormSection;
