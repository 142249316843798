import React from "react";
import { Card } from "reactstrap";
const HeroSection = ({ nextStep }) => {
    return (
        <div className="Herocontainer row">
            <Card className=" col-md-12 col-lg-6 HeroCard">
                <div className="absoluteText">
                    <p className="text-white absoluteP">
                        Al meer dan 200.000
                        <br />
                        mensen gingen je voor
                    </p>
                </div>
                <h5 className="noto-sans mb-2">GRATIS Stopadvies</h5>
                <h1 className="noto-sans mb-3 homeHeading mt-3">
                    Succesvol stoppen met ROKEN of VAPEN!
                    {/* ROKERS GEZOCHT <b>die willen stoppen!</b> */}
                </h1>

                <p>
                    <b>Wil jij ook stoppen me roken of vapen?</b> Check dan nu
                    of jij in aanmerking komt voor ons gratis Stopadvies.
                    Vergroot jouw kans om echt te stoppen en ontvang alle
                    informatie en adviezen die jou hierbij helpen.
                    {/* Ontvang nu ons <b>GRATIS Stopadvies</b> en vergroot jouw
                    kans om succesvol te stoppen met roken! */}
                </p>
                {/* <p className="mb-4">
                    Beantwoord <u onClick={nextStep}>
                        een paar korte vragen
                    </u>{" "}
                    en ontvang direct gratis stopadvies!
                </p> */}
                <h4 className="mb-4">Check nu of jij in aanmerking komt!</h4>
                <button onClick={nextStep} className="mb-3">
                    GRATIS Stopadvies {/* Ik wil stoppen */}
                    <i class="fas fa-thin fa-chevron-right mx-3"></i>
                </button>
                <p>
                    Het doel van Stichting Stop Bewust is om mensen te helpen
                    met juiste informatie en advies over stoppen met roken. Wij
                    hebben geen winstoogmerk.
                    {/* Het doel van Stichting Stop Bewust is om mensen te helpen
                    met juiste informatie en advies over stoppen met roken. Wij
                    hebben geen winstoogmerk. */}
                </p>
            </Card>
        </div>
    );
};

export default HeroSection;
