import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
const ThankYouPage = (props) => {
    const [idToStore, setIdToStore] = useState(null);

    const { profile } = useSelector((state) => state.leads);

    useEffect(() => {
        setIdToStore(profile?.profile?.id);
        // let tmps =
        //     idToStore != undefined && idToStore != "" && idToStore != null
        //         ? "yes"
        //         : "no";
        // alert(tmps);
    }, []);
    return (
        <div className="Thankyoucontainer row">
            <Card className=" col-sm-10 col-md-6 col-lg-4 ThankyouCard">
                {props.children}

                {idToStore != undefined &&
                idToStore != "" &&
                idToStore != null ? (
                    <img
                        referrerpolicy="no-referrer-when-downgrade"
                        src={`https://republish.gratismeedoen.nl/m/6175/a262efc9e932/?event=7207&unique_conversion_id=${idToStore}`}
                        style={{
                            width: "1px",
                            height: "1px",
                            border: "0px",
                        }}
                    />
                ) : (
                    ""
                )}
            </Card>
        </div>
    );
};

export default ThankYouPage;
